import React, { Component } from "react";
import { Route, Switch, Link, withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

//import Delete from "mdi-material-ui/Delete";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
//import TitleBarDeleteButton from "../../components/TitleBarDeleteButton";

import ApplicationStore from "../../stores/ApplicationStore";
import DeviceProfileStore from "../../stores/DeviceProfileStore";
import SessionStore from "../../stores/SessionStore";
//import Admin from "../../components/Admin";
import DeviceStore from "../../stores/DeviceStore";
import UpdateDevice from "./UpdateDevice";
//import DeviceKeys from "./DeviceKeys";
import DeviceActivation from "./DeviceActivation"
//import DeviceData from "./DeviceData";
import DeviceFrames from "./DeviceFrames";

import theme from "../../theme";
import DownlinkPackets from "./DownlinkPackets";
import UplinkPackets from "./UplinkPackets";


const styles = {
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px"
  },
};


class DeviceLayout extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      admin: false,
    };

    this.onChangeTab = this.onChangeTab.bind(this);
    this.locationToTab = this.locationToTab.bind(this);
    this.setIsAdmin = this.setIsAdmin.bind(this);
  }

  componentDidMount() {
    ApplicationStore.get(this.props.match.params.applicationID, resp => {
      this.setState({
        application: resp,
      });
    });

    DeviceStore.get(this.props.match.params.devEUI, resp1 => {
      DeviceProfileStore.get(resp1.device.deviceProfileID, resp2 => {
        this.setState({
          deviceProfile: resp2,
        });

        DeviceStore.getKeys(this.props.match.params.devEUI, keys => {
          resp1.device.nwkKey = keys.deviceKeys.nwkKey;
          resp1.device.appKey = keys.deviceKeys.appKey;
          this.setState({
            device: resp1,
          });
        });
      });
    });

    SessionStore.on("change", this.setIsAdmin);

    this.locationToTab();
    this.setIsAdmin();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
  }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin() || SessionStore.isOrganizationAdmin(this.props.match.params.organizationID),
    }, () => {
      // we need to update the tab index, as for non-admins, some tabs are hidden
      this.locationToTab();
    });
  }

  onChangeTab(e, v) {
    this.setState({
      tab: v,
    });
  }

  locationToTab() {
    let tab = 0;

    //if (window.location.href.endsWith("/keys")) {
      //tab = 1;
    //} else
    if (window.location.href.endsWith("/activation")) {
      tab = 1;
    //} else if (window.location.href.endsWith("/data")) {
      //tab = 3;
    } else if (window.location.href.endsWith("/uplink-packets")) {
      tab = 2;
    } else if (window.location.href.endsWith("/downlink-packets")) {
      tab = 3;
    }

    /*if (tab > 0 && !this.state.admin) {
      tab = tab - 1;
    }*/

    this.setState({
      tab: tab,
    });
  }

  render() {
    if (this.state.application === undefined || this.state.device === undefined|| this.state.deviceProfile === undefined) {
      return(<div></div>);
    }

    return(
      <Grid container spacing={24}>
        <TitleBar>
            <TitleBarTitle to={`/organizations/${this.props.match.params.organizationID}/applications`} title="Applications" />
            <TitleBarTitle title="/" />
            <TitleBarTitle to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}`} title={this.state.application.application.name} />
            <TitleBarTitle title="/" />
            <TitleBarTitle to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}`} title="End Devices" />
            <TitleBarTitle title="/" />
            <TitleBarTitle title={this.state.device.device.name} />

           </TitleBar>

        <Grid item xs={12}>
          <Tabs
            value={this.state.tab}
            onChange={this.onChangeTab}
            indicatorColor="primary"
            className={this.props.classes.tabs}
            variant="scrollable"
            scrollButtons="off"
          >
            <Tab label="Settings" component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/end-devices/${this.props.match.params.devEUI}`} />
              {/*{this.state.admin && <Tab label="OTAA Keys" component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/devices/${this.props.match.params.devEUI}/keys`} />}*/}
            <Tab label="Activation" component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/end-devices/${this.props.match.params.devEUI}/activation`} />
              {/*<Tab label="Live device data" component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/devices/${this.props.match.params.devEUI}/data`} />*/}
            <Tab label="Uplink Packets" component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/end-devices/${this.props.match.params.devEUI}/uplink-packets`} />
            <Tab label="Downlink Packets" component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/end-devices/${this.props.match.params.devEUI}/downlink-packets`} />
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          <Switch>
            <Route exact path={`${this.props.match.path}`} render={props => <UpdateDevice application={this.state.application.application} device={this.state.device.device} admin={this.state.admin} {...props} />} />
            {/*<Route exact path={`${this.props.match.path}/keys`} render={props => <DeviceKeys device={this.state.device.device} admin={this.state.admin} {...props} />} />*/}
            <Route exact path={`${this.props.match.path}/activation`} render={props => <DeviceActivation device={this.state.device.device} admin={this.state.admin} deviceProfile={this.state.deviceProfile.deviceProfile} {...props} />} />
            {/*<Route exact path={`${this.props.match.path}/data`} render={props => <DeviceData device={this.state.device.device} admin={this.state.admin} {...props} />} />*/}
            <Route exact path={`${this.props.match.path}/uplink-packets`} render={props => <UplinkPackets device={this.state.device.device} admin={this.state.admin} {...props} />} />
            <Route exact path={`${this.props.match.path}/downlink-packets`} render={props => <DownlinkPackets device={this.state.device.device} admin={this.state.admin} {...props} />} />
          </Switch>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(DeviceLayout));
