import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
//import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";

//import theme from "../theme";
//import AddIcon from "@material-ui/core/SvgIcon/SvgIcon";
import AddIcon from '@material-ui/icons/Add';
//import DeleteIcon from '@material-ui/icons/Delete';
//import grey from "@material-ui/core/colors/grey";


const styles = {
    button: {
        //marginLeft: theme.spacing.unit,
        //color: theme.palette.common.white,
        //backgroundColor: theme.palette.secondary.main
    },
    icon: {
        //color: theme.palette.common.white,
    },
};


class TitleBarAddButton extends Component {
    render() {
        let component = "button";
        //let icon = null;

        if (this.props.to !== undefined) {
            component = Link
        }

        /*if (this.props.icon !== undefined) {
            icon = React.cloneElement(this.props.icon, {
                className: this.props.classes.icon,
                fontSize: "large"
            })
        }*/

        return(
            <IconButton color={this.props.color || "default"} className={this.props.classes.button}
                        to={this.props.to} onClick={this.props.onClick} component={component}>
                <AddIcon />
            </IconButton>
        );
        /*<Button
        variant="outlined"
        color={this.props.color || "default"}
        className={this.props.classes.button}
        component={component}
        to={this.props.to}
        onClick={this.props.onClick}
      >
        {icon}
        {this.props.label}
      </Button>*/

    }
}

export default withStyles(styles)(TitleBarAddButton);