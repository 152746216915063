import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
//import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AccountGroup, Apps, RadioTower, Shape, Logout, LockReset } from 'mdi-material-ui';
import SessionStore from "../stores/SessionStore";
import OrganizationStore from "../stores/OrganizationStore";
import Admin from "./Admin";

import theme from "../theme";
//import MenuItem from "@material-ui/core/MenuItem";


const styles = {
  drawerPaper: {
    position: "fixed",
    width: 270,
    paddingLeft: theme.spacing.unit,
    paddingTop: theme.spacing.unit * 9,
  },
  select: {
    paddingTop: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 1,
  },
};

class SideNav extends Component {
  constructor() {
    super();

    this.state = {
      open: false,
      organization: null,
      organizationCanHaveGateways: false,
      cacheCounter: 0,
    };


    this.onChange = this.onChange.bind(this);
    this.getOrganizationOption = this.getOrganizationOption.bind(this);
    this.getOrganizationOptions = this.getOrganizationOptions.bind(this);
    this.getOrganizationFromLocation = this.getOrganizationFromLocation.bind(this);
  }

  componentDidMount() {
    /*SessionStore.on("organization.change", () => {
      OrganizationStore.get(SessionStore.getOrganizationID(), resp => {
        this.setState({
          organization: resp.organization,
        });
      });
    });*/

    /*OrganizationStore.on("create", () => {
      this.setState({
        cacheCounter: this.state.cacheCounter + 1,
      });
    });

    OrganizationStore.on("change", (org) => {
      if (this.state.organization !== null && this.state.organization.id === org.id) {
        this.setState({
          organization: org,
        });
      }
    });

    OrganizationStore.on("delete", id => {
      if (this.state.organization !== null && this.state.organization.id === id) {
        this.setState({
          organization: null,
        });
      }

      this.setState({
        cacheCounter: this.state.cacheCounter + 1,
      });
    });*/

      this.setState({
          organization: SessionStore.getOrganization(),
      });

      if (SessionStore.getOrganizationID() !== null) {
          OrganizationStore.get(SessionStore.getOrganizationID(), resp => {
              this.setState({
                  organizationCanHaveGateways: resp.organization.canHaveGateways,
              });
          });
      }

    //this.getOrganizationFromLocation();
  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) {
      return;
    }

      if (SessionStore.getOrganizationID() !== null) {
          OrganizationStore.get(SessionStore.getOrganizationID(), resp => {
              this.setState({
                  organizationCanHaveGateways: resp.organization.canHaveGateways,
              });
          });
      }

    //this.getOrganizationFromLocation();
  }

  onChange(e) {
    this.props.history.push(`/organizations/${e.target.value}/applications`);
  }

  getOrganizationFromLocation() {
    if(this.state.organization === null) {
        const organizationRe = /\/organizations\/(\d+)/g;
        const match = organizationRe.exec(this.props.history.location.pathname);

        if (match !== null && (this.state.organization === null || this.state.organization.id !== match[1])) {
            SessionStore.setOrganizationID(match[1]);

            if (SessionStore.getOrganizationID() !== null) {
                OrganizationStore.get(SessionStore.getOrganizationID(), resp => {
                    this.setState({
                        organization: resp.organization,
                    });
                });
            }
        }
    }
  }

  getOrganizationOption(id, callbackFunc) {
    OrganizationStore.get(id, resp => {
      callbackFunc({label: resp.organization.name, value: resp.organization.id});
    });
  }

  getOrganizationOptions(search, callbackFunc) {
    OrganizationStore.list(search, 10, 0, resp => {
      const options = resp.result.map((o, i) => {return {label: o.name, value: o.id}});
      callbackFunc(options);
    });
  }

  onLogout() {
    SessionStore.logout(() => {
    });
  }

  render() {
      if (this.state.organization === undefined) {
          return(<div></div>);
      }

    return(
      <Drawer
        variant="persistent"
        anchor="left"
        open={this.props.open}
        classes={{paper: this.props.classes.drawerPaper}}
      >
        {this.state.organization && <List>
            <ListItem button component={Link} to={`/organizations/${this.state.organization.organizationID}/applications`}>
                <ListItemIcon>
                    <Apps />
                </ListItemIcon>
                <ListItemText primary="Applications" />
            </ListItem>
            {/*<Admin>
              <ListItem button component={Link} to={`/organizations/${this.state.organization.id}/edit`}>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Org. settings" />
            </ListItem>*/}
            {/*<ListItem button component={Link} to={`/organizations/${this.state.organization.id}/users`}>
              <ListItemIcon>
                <Account />
              </ListItemIcon>
              <ListItemText primary="Org. users" />
            </ListItem>*/}
            {/*<ListItem button component={Link} to={`/organizations/${this.state.organization.id}/service-profiles`}>
            <ListItemIcon>
              <AccountSettingsVariant />
            </ListItemIcon>
              <ListItemText primary="Service-profiles" />
          </ListItem>
        </Admin>*/}
          <ListItem button component={Link} to={`/organizations/${this.state.organization.organizationID}/device-profiles`}>
            <ListItemIcon>
              <Shape />
            </ListItemIcon>
            <ListItemText primary="Device Profiles" />
          </ListItem>
          {this.state.organizationCanHaveGateways && <ListItem button component={Link} to={`/organizations/${this.state.organization.organizationID}/gateways`}>
            <ListItemIcon>
              <RadioTower />
            </ListItemIcon>
            <ListItemText primary="Gateways" />
          </ListItem>}
            <Admin>
                {/*<ListItem button component={Link} to="/network-servers">
              <ListItemIcon>
                <Server />
              </ListItemIcon>
              <ListItemText primary="Network-servers" />
            </ListItem>*/}
                {/*<ListItem button component={Link} to="/gateway-profiles">
              <ListItemIcon>
                <RadioTower />
              </ListItemIcon>
              <ListItemText primary="Gateway-profiles" />
            </ListItem>*/}
                <ListItem button component={Link} to="/organizations">
                    <ListItemIcon>
                        <AccountGroup />
                    </ListItemIcon>
                    <ListItemText primary="Organizations" />
                </ListItem>
                {/*<ListItem button component={Link} to="/users">
              <ListItemIcon>
                <Account />
              </ListItemIcon>
              <ListItemText primary="All users" />
            </ListItem>*/}

                {/*<div>
                <AutocompleteSelect
                    id="organizationID"
                    margin="none"
                    value={organizationID}
                    onChange={this.onChange}
                    getOption={this.getOrganizationOption}
                    getOptions={this.getOrganizationOptions}
                    className={this.props.classes.select}
                    triggerReload={this.state.cacheCounter}
                />
            </div>*/}
            </Admin>
            <ListItem button onClick={this.onLogout}>
                <ListItemIcon>
                    <Logout />
                </ListItemIcon>
                <ListItemText primary="Log Out" />
            </ListItem>
            <ListItem button component={Link} to={`/users/${this.props.user.id}/password`}>
                <ListItemIcon>
                    <LockReset />
                </ListItemIcon>
                <ListItemText primary="Change Password" />
            </ListItem>
            {/*<ListItem button component={Link} to={`/organizations/${this.state.organization.id}/end-devices`}>
                <ListItemIcon>
                    <Apps />
                </ListItemIcon>
                <ListItemText primary="End Devices" />
            </ListItem>*/}
            {/*<ListItem button component={Link} to={`/organizations/${this.state.organization.id}/multicast-groups`}>
            <ListItemIcon>
              <Rss />
            </ListItemIcon>
            <ListItemText primary="Multicast-groups" />
          </ListItem>*/}
        </List>}
      </Drawer>
    );
  }
}

export default withRouter(withStyles(styles)(SideNav));
