import React, { Component } from "react";
import { Route, Switch, Link, withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

//import Delete from "mdi-material-ui/Delete";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
//import TitleBarDeleteButton from "../../components/TitleBarDeleteButton";
//import Admin from "../../components/Admin";

import OrganizationStore from "../../stores/OrganizationStore";
import SessionStore from "../../stores/SessionStore";
import ListOrganizationUsers from "../organizations/ListOrganizationUsers";
import UpdateOrganization from "./UpdateOrganization";

import theme from "../../theme";


const styles = {
    tabs: {
        borderBottom: "1px solid " + theme.palette.divider,
        height: "48px"
    },
};


class OrganizationLayout extends Component {
    constructor() {
        super();
        this.state = {
            tab: 0,
            admin: false,
        };

        this.deleteOrganization = this.deleteOrganization.bind(this);
        this.locationToTab = this.locationToTab.bind(this);
        this.onChangeTab = this.onChangeTab.bind(this);
        this.setIsAdmin = this.setIsAdmin.bind(this);
    }

    componentDidMount() {
        OrganizationStore.get(this.props.match.params.organizationID, resp => {
            this.setState({
                organization: resp,
            });
        });

        //SessionStore.on("change", this.setIsAdmin);

        this.setIsAdmin();
        this.locationToTab();
    }

    componentWillUnmount() {
        //SessionStore.removeListener("change", this.setIsAdmin);
    }

    componentDidUpdate(oldProps) {
        if (this.props === oldProps) {
            return;
        }

        this.locationToTab();
    }

    setIsAdmin() {
        this.setState({
            admin: SessionStore.isAdmin() || SessionStore.isOrganizationAdmin(this.props.match.params.organizationID),
        });
    }

    deleteOrganization() {
        if (window.confirm("Are you sure you want to delete this organization?")) {
            OrganizationStore.delete(this.props.match.params.organizationID, resp => {
                this.props.history.push(`/organizations`);
            });
        }
    }

    locationToTab() {
        let tab = 0;

        if (window.location.href.endsWith("/edit")) {
            tab = 1;
        }

        this.setState({
            tab: tab,
        });
    }

    onChangeTab(e, v) {
        this.setState({
            tab: v,
        });
    }

    render() {
        if (this.state.organization === undefined) {
            return(<div></div>);
        }

        return(
            <Grid container spacing={24}>
                <TitleBar>
                    <TitleBarTitle to={`/organizations`} title="Organizations" />
                    <TitleBarTitle title="/" />
                    <TitleBarTitle title={this.state.organization.organization.name} />
                </TitleBar>

                <Grid item xs={12}>
                    <Tabs
                        value={this.state.tab}
                        onChange={this.onChangeTab}
                        indicatorColor="primary"
                        className={this.props.classes.tabs}
                        variant="scrollable"
                        scrollButtons="off"
                    >
                        <Tab label="Users" component={Link} to={`/organizations/${this.state.organization.organization.id}`} />
                        {this.state.admin && <Tab label="Settings" component={Link} to={`/organizations/${this.state.organization.organization.id}/edit`} />}
                        </Tabs>
                </Grid>

                <Grid item xs={12}>
                    <Switch>
                        <Route exact path={`${this.props.match.path}/edit`} render={props => <UpdateOrganization organization={this.state.organization.organization} {...props} />} />
                        <Route exact path={`${this.props.match.path}`} render={props => <ListOrganizationUsers organizationID={this.state.organization.organization.id} {...props} />} />
                    </Switch>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(withRouter(OrganizationLayout));
