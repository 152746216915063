import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';

//import Delete from "mdi-material-ui/Delete";
//import LockReset from "mdi-material-ui/LockReset";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarKeyButton from "../../components/TitleBarKeyButton";
import TitleBarDeleteButton from "../../components/TitleBarDeleteButton";
import UserStore from "../../stores/UserStore";
import OrganizationStore from "../../stores/OrganizationStore";
import UpdateUser from "./UpdateUser";


class UserLayout extends Component {
  constructor() {
    super();
    this.state = {
    };

    this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount() {
    UserStore.get(this.props.match.params.userID, resp => {
      this.setState({
        user: resp,
      });
    });
  OrganizationStore.get(this.props.match.params.organizationID, resp => {
      this.setState({
          organization: resp,
      });
  });
  }

  deleteUser() {
    if (window.confirm("Are you sure you want to delete this user?")) {
      UserStore.delete(this.props.match.params.userID, () => {
        this.props.history.push(`/organizations`);
      });
    }
  }

  render() {
    if (this.state.user === undefined || this.state.organization === undefined) {
      return(<div></div>);
    }

    return(
      <Grid container spacing={24}>
        <TitleBar
          buttons={[
            <TitleBarKeyButton
              to={`/users/${this.props.match.params.userID}/password`}
            />,
            <TitleBarDeleteButton
              onClick={this.deleteUser}
            />,
          ]}
        >
            <TitleBarTitle to={`/organizations`} title="Organizations" />
            <TitleBarTitle title="/" />
            <TitleBarTitle to={`/organizations/${this.props.match.params.organizationID}`} title={this.state.organization.organization.name} />
            <TitleBarTitle title="/" />
            <TitleBarTitle to={`/organizations/${this.props.match.params.organizationID}`} title="Users" />
            <TitleBarTitle title="/" />
            <TitleBarTitle title={this.state.user.user.username} />
        </TitleBar>

        <Grid item xs={12}>
          <UpdateUser user={this.state.user.user} />
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(UserLayout);
