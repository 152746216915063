import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
//import Avatar from "@material-ui/core/Avatar";
//import Chip from "@material-ui/core/Chip";
//import Grid from "@material-ui/core/Grid";
//import Button from '@material-ui/core/Button';
//import CircularProgress from '@material-ui/core/CircularProgress';
//import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';

//import Play from "mdi-material-ui/Play";
//import Pause from "mdi-material-ui/Pause";
//import Download from "mdi-material-ui/Download";
//import Delete from "mdi-material-ui/Delete";
//import HelpCircleOutline from "mdi-material-ui/HelpCircleOutline";
//import AlertCircleOutline from "mdi-material-ui/AlertCircleOutline";

import fileDownload from "js-file-download";

//import LoRaWANFrameLog from "../../components/LoRaWANFrameLog";
import DeviceStore from "../../stores/DeviceStore";
import theme from "../../theme";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/TableRow";
import moment from "moment";
import TableFooter from "@material-ui/core/TableFooter";
//import axios from "axios";
//import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';


const styles = {
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0,
        zIndex: 10,
        fontWeight: 500
    },
    table: {
        tableLayout: 'auto',
        fontFamily: "Roboto"
    },
    root: {
        width: '100%',
        marginTop: theme.spacing.unit,
        overflowX: 'auto',
    },
    /*buttons: {
      textAlign: "right",
    },
    button: {
      marginLeft: 2 * theme.spacing.unit,
    },
    icon: {
      marginRight: theme.spacing.unit,
    },
    center: {
      textAlign: "center",
    },
    progress: {
      marginTop: 4 * theme.spacing.unit,
    },*/
};


class UplinkPackets extends Component {
    constructor() {
        super();

        this.state = {
            paused: false,
            connected: false,
            frames: [],
            dialogOpen: false,
        };

        this.onFrame = this.onFrame.bind(this);
    }

    componentDidMount() {
        //const conn = DeviceStore.getFrameLogsConnection(this.props.match.params.devEUI, this.onFrame);
        const conn = DeviceStore.getUplinkPackets(this.props.match.params.devEUI, this.onFrame);
        this.setState({
            //wsConn: conn,
            conn: conn
        });

        //DeviceStore.on("ws.status.change", this.setConnected);
        //this.setConnected();

    }

    componentWillUnmount() {
        try {
            this.state.conn.close();
        }
        catch(err) {}
        //this.state.wsConn.close();

        //DeviceStore.removeListener("ws.status.change", this.setConnected);
    }

    onDownload() {
        const dl = this.state.frames.map((frame, i) => {
            return {
                uplinkMetaData: frame.uplinkMetaData,
                downlinkMetaData: frame.downlinkMetaData,
                phyPayload: frame.phyPayload,
            };
        });

        fileDownload(JSON.stringify(dl, null, 4), `gateway-${this.props.match.params.gatewayID}.json`);
    }

    setConnected() {
        this.setState({
            connected: DeviceStore.getWSFramesStatus(),
        });
    }

    onFrame(frame) {
        /*if (this.state.paused) {
          return;
        }*/

        //console.log(frame);

        let frames = this.state.frames;
        const now = new Date();

        //if (frame.uplinkFrame !== undefined) {
        frames.unshift({
            id: now.getTime(),
            receivedAt: now,
            data: frame,
        });
        //}

        frames.sort((a,b) => (a.recvTime > b.recvTime) ? -1 : ((b.recvTime > a.recvTime) ? 1 : 0));
        frames = frames.slice(0, 99);

        //console.log(frame);

        this.setState({
            frames: frames,
        });
    }

    render() {
        const frames = this.state.frames.map((row, i) =>
            <TableRow key={row.data.recvTime}>
                <TableCell
                    style={{whiteSpace: 'nowrap'}}>{moment(row.data.recvTime).format("YYYY-MM-DD HH:mm:ss.SSS")}</TableCell>
                <TableCell>{row.data.fCntUp}</TableCell>
                <TableCell>{row.data.fPort}</TableCell>
                <TableCell>{row.data.ack ? "yes" : "no"}</TableCell>
                <TableCell>{(row.data.frmPayload !== '') ? '0x' + row.data.frmPayload : ''}</TableCell>
                <TableCell>{row.data.gwInfo[0].gwId}</TableCell>
                <TableCell>{row.data.ulFreq}</TableCell>
                <TableCell>{row.data.dataRate}</TableCell>
                <TableCell>{row.data.gwInfo[0].rssi}</TableCell>
                <TableCell>{row.data.gwInfo[0].snr}</TableCell>
            </TableRow>
        );

        return(
            <Paper className={this.props.classes.root}>
                <Table className={this.props.classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={this.props.classes.head}>Received time</TableCell>
                            <TableCell className={this.props.classes.head}>Frame counter</TableCell>
                            <TableCell className={this.props.classes.head}>Frame port</TableCell>
                            <TableCell className={this.props.classes.head}>Ack</TableCell>
                            <TableCell className={this.props.classes.head}>Frame payload (decrypted)</TableCell>
                            <TableCell className={this.props.classes.head}>Gateway ID</TableCell>
                            <TableCell className={this.props.classes.head}>Freq (MHz)</TableCell>
                            <TableCell className={this.props.classes.head}>Data rate</TableCell>
                            <TableCell className={this.props.classes.head}>RSSI (dBm)</TableCell>
                            <TableCell className={this.props.classes.head}>SNR (dB)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {frames}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Paper>
        );
    }
}

export default withStyles(styles)(UplinkPackets);