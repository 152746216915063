import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import { CardContent } from "@material-ui/core";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import OrganizationForm from "./OrganizationForm";
import OrganizationStore from "../../stores/OrganizationStore";
import ServiceProfileStore from "../../stores/ServiceProfileStore";


class CreateOrganization extends Component {
  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(organization) {
    OrganizationStore.create(organization, resp => {
        let sp = {
            name: this.props.match.params.organizationID,
            networkServerID: "1",
            addGWMetaData: true,
            nwkGeoLoc: false,
            devStatusReqFreq: 0,
            reportDevStatusBattery: false,
            reportDevStatusMargin: false,
            drMin: 0,
            drMax: 5
        };
        sp.organizationID = resp.id;

        ServiceProfileStore.create(sp, resp => {
            this.props.history.push("/organizations");
        });
    });


  }

  render() {
    return(
      <Grid container spacing={24}>
        <TitleBar>
          <TitleBarTitle title="Organizations" to="/organizations" />
          <TitleBarTitle title="/" />
          <TitleBarTitle title="Create" />
        </TitleBar>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <OrganizationForm
                submitLabel="Add"
                onSubmit={this.onSubmit}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(CreateOrganization);