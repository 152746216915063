import React from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import ApplicationStore from "../../stores/ApplicationStore";
import DeviceProfileStore from "../../stores/DeviceProfileStore";


const styles = {
  formLabel: {
    fontSize: 12,
  },
};


class DeviceForm extends FormComponent {
  constructor() {
    super();

      this.state = {
          deviceProfileSupportsJoin:  false
      };

    this.getApplicationOption = this.getApplicationOption.bind(this);
    this.getApplicationOptions = this.getApplicationOptions.bind(this);
    this.getDeviceProfileOption = this.getDeviceProfileOption.bind(this);
    this.getDeviceProfileOptions = this.getDeviceProfileOptions.bind(this);
    this.onDeviceProfileChange = this.onDeviceProfileChange.bind(this);
  }

  getApplicationOption(id, callbackFunc) {
      ApplicationStore.get(id, resp => {
          callbackFunc({label: resp.application.name, value: resp.application.id});
      });
  }

  getApplicationOptions(search, callbackFunc) {
      ApplicationStore.list(0, null, 999, 0, resp => {
          const options = resp.result.map((dp, i) => {return {label: dp.name, value: dp.id}});
          callbackFunc(options);
      });
  }

  getDeviceProfileOption(id, callbackFunc) {
    DeviceProfileStore.get(id, resp => {
      callbackFunc({label: resp.deviceProfile.name, value: resp.deviceProfile.id});
    });
  }

  getDeviceProfileOptions(search, callbackFunc) {
    DeviceProfileStore.list(0, this.props.match.params.applicationID, 999, 0, resp => {
      const options = resp.result.map((dp, i) => {return {label: dp.name, value: dp.id}});
      callbackFunc(options);
    });
  }

  onDeviceProfileChange(e) {
    this.onChange(e);
    DeviceProfileStore.get(this.state.object.deviceProfileID, resp => {
        this.setState({
            deviceProfileSupportsJoin: resp.deviceProfile.supportsJoin
        });
    });
  }

  render() {
    if (this.state.object === undefined) {
      return(<div></div>);
    }

      /*let dis;

      if (this.props.update) {
          dis = true;
      } else {
          dis = "false";
      }*/

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >
        <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>Device Profile</FormLabel>
          <AutocompleteSelect
              id="deviceProfileID"
              label="Device Profile"
              margin="normal"
              value={this.state.object.deviceProfileID}
              onChange={this.onDeviceProfileChange}
              getOption={this.getDeviceProfileOption}
              getOptions={this.getDeviceProfileOptions}
          />
          <FormHelperText>
            Choose one of pre-registered device profiles.
          </FormHelperText>
        </FormControl>
        <TextField
          id="name"
          label="Name"
          helperText="A friendly name. Only letters, digits and dashes are allowed."
          margin="normal"
          value={this.state.object.name || ""}
          onChange={this.onChange}
          inputProps={{
            pattern: "[\\w-]+",
          }}
          fullWidth
          required
        />
          {/*<TextField
          id="description"
          label="Description"
          margin="normal"
          value={this.state.object.description || ""}
          onChange={this.onChange}
          fullWidth
          required
        />*/}
          <TextField
          id="devEUI"
          label="Device EUI"
          placeholder="0000000000000000"
          helperText="The devices's ID in EUI-64 format."
          margin="normal"
          onChange={this.onChange}
          value={this.state.object.devEUI || ""}
          inputProps={{
            pattern: "[A-Fa-f0-9]{16}"
          }}
          fullWidth
          required
          disabled={this.props.update}
          />
          {/*<FormControl fullWidth margin="normal">
              <FormLabel className={this.props.classes.formLabel} required>Application</FormLabel>
              <AutocompleteSelect
                  id="applicationID"
                  label="Application"
                  value={this.state.object.applicationID}
                  onChange={this.onChange}
                  getOption={this.getApplicationOption}
                  getOptions={this.getApplicationOptions}
              />
          </FormControl>*/}
          <TextField
          id="nwkKey"
          label="OTAA Network Key"
          helperText="For LoRaWAN™ 1.0.x end devices this is the OTAA application key."
          inputProps={{
            pattern: "[A-Fa-f0-9]{32}",
          }}
          onChange={this.onChange}
          value={this.state.object.nwkKey}
          margin="normal"
          fullWidth
        /><TextField
          id="appKey"
          label="OTAA Application key"
          helperText="Use for LoRaWAN™ 1.1.x devices only"
          inputProps={{
              pattern: "[A-Fa-f0-9]{32}",
          }}
          onChange={this.onChange}
          value={this.state.object.appKey}
          margin="normal"
          fullWidth
      />
        <FormControl margin="normal">
          <FormGroup>
            <FormControlLabel
                label="Disable frame-counter validation"
                control={
                  <Checkbox
                      id="skipFCntCheck"
                      checked={!!this.state.object.skipFCntCheck}
                      onChange={this.onChange}
                      color="primary"
                  />
                }
            />
          </FormGroup>
          <FormHelperText>
            Note that disabling the frame-counter validation will compromise security as it enables people to perform replay-attacks.
          </FormHelperText>
        </FormControl>
      </Form>
    );
  }
}

export default withStyles(styles)(DeviceForm);
