import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";
//import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
//import Checkbox from '@material-ui/core/Checkbox';

//import AddIcon from '@material-ui/icons/Add';
//import DeleteIcon from '@material-ui/icons/Delete';
//import Plus from "mdi-material-ui/Plus";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
//import TitleBarButton from "../../components/TitleBarButton";
import TitleBarAddButton from "../../components/TitleBarAddButton";
import DataTable from "../../components/DataTable";
import Admin from "../../components/Admin";
import ApplicationStore from "../../stores/ApplicationStore";
import TitleBarDeleteButton from "../../components/TitleBarDeleteButton";
import Delete from "@material-ui/core/SvgIcon/SvgIcon";
//import DeviceStore from "../../stores/DeviceStore";


class ListApplications extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
  }

    static deleteApplication(id) {
        return function() {
            if (window.confirm("Are you sure you want to delete this application?")) {
                ApplicationStore.delete(id, resp => {
                    window.location.reload();
                    //this.props.history.push(`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}`);
                });
            }
        }
    }

  getPage(limit, offset, callbackFunc) {
    ApplicationStore.list("", this.props.match.params.organizationID, limit, offset, callbackFunc);
  }

  getRow(obj) {
    return(
      <TableRow key={obj.id}>
          <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/applications/${obj.id}`}>{obj.name}</TableCellLink>
          <TableCell >00000000-0000-0000-0000-{obj.id.padStart(12, "0")}</TableCell>
          <TableCell align="right"><TitleBarDeleteButton
              label="Delete"
              icon={<Delete />}
              color="secondary"
              onClick={ListApplications.deleteApplication(obj.id)}
          /></TableCell>
         {/*<TableCellLink to={`/organizations/${this.props.match.params.organizationID}/service-profiles/${obj.serviceProfileID}`}>{obj.serviceProfileName}</TableCellLink>*/}
          {/*<TableCell>{obj.description}</TableCell>*/}
      </TableRow>
    );
  }

  render() {
    return(
      <Grid container spacing={24}>
        <TitleBar
          buttons={
            <Admin organizationID={this.props.match.params.organizationID}>
                <TitleBarAddButton to={`/organizations/${this.props.match.params.organizationID}/applications/create`} />
            </Admin>
          }
        >
          <TitleBarTitle title="Applications" />
        </TitleBar>
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>ID</TableCell>
                <TableCell></TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default ListApplications;
