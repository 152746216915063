import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

//import Plus from "mdi-material-ui/Plus";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
import TitleBarAddButton from "../../components/TitleBarAddButton";
import DataTable from "../../components/DataTable";
import Admin from "../../components/Admin";
import DeviceProfileStore from "../../stores/DeviceProfileStore";
import TitleBarDeleteButton from "../../components/TitleBarDeleteButton";
import Delete from "@material-ui/core/SvgIcon/SvgIcon";
//import DeviceStore from "../../stores/DeviceStore";


class ListDeviceProfiles extends Component {
  constructor() {
    super();

    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
  }

    static deleteDeviceProfile(id) {
        return function() {
            if (window.confirm("Are you sure you want to delete this device profile?")) {
                DeviceProfileStore.delete(id, resp => {
                    window.location.reload();
                    //this.props.history.push(`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}`);
                });
            }
        }
    }

  getPage(limit, offset, callbackFunc) {
    DeviceProfileStore.list(this.props.match.params.organizationID, 0, limit, offset, callbackFunc);
  }

  getRow(obj) {
    return(
      <TableRow key={obj.id}>
        <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/device-profiles/${obj.id}`}>{obj.name}</TableCellLink>
          <TableCell>{obj.id}</TableCell>
          <TableCell align="right"><TitleBarDeleteButton
              label="Delete"
              icon={<Delete />}
              color="secondary"
              onClick={ListDeviceProfiles.deleteDeviceProfile(obj.id)}
          /></TableCell>
      </TableRow>
    );
  }

  render() {
    return(
      <Grid container spacing={24}>
        <TitleBar
          buttons={
            <Admin organizationID={this.props.match.params.organizationID}>
              <TitleBarAddButton to={`/organizations/${this.props.match.params.organizationID}/device-profiles/create`} />
            </Admin>
          }
        >
          <TitleBarTitle title="Device Profiles" />
        </TitleBar>
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                  <TableCell>Name</TableCell>
                <TableCell>ID</TableCell>
                  <TableCell></TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default ListDeviceProfiles;
