import { createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";


const theme = createMuiTheme({
    palette: {
      primary: {
          main: "#000000"
      },
        secondary: {
            main: grey[600]
        }//,
        //background: {
            //default: '#ffffff',
        //},
    },
    typography: {
        useNextVariants: true,
    },
});
  
export default theme;
